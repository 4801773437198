import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ru-statut',
  templateUrl: './ru-statut.component.html',
  styleUrls: ['./ru-statut.component.css']
})
export class RuStatutComponent implements OnInit {

  constructor() { }
  step = -1;

  ngOnInit() {
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

}
