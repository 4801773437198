import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-de-intro',
  templateUrl: './de-intro.component.html',
  styleUrls: ['./de-intro.component.css']
})
export class DeIntroComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
