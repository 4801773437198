import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";

@Component({
  selector: 'app-dialog-video',
  templateUrl: './dialog-video.component.html',
  styleUrls: ['./dialog-video.component.css']
})
export class DialogVideoComponent implements OnInit {

  videoObj: any;
  close: any;

  constructor(private dialogRef: MatDialogRef<DialogVideoComponent>, @Inject(MAT_DIALOG_DATA) data: any) { 
    this.videoObj = data.videoObj;
    this.close = data.close;
   }

  ngOnInit() {
    // console.log(this.videoObj)
  }

}
