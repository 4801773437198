import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fr-intro',
  templateUrl: './fr-intro.component.html',
  styleUrls: ['./fr-intro.component.css']
})
export class FrIntroComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
