import { Component, OnInit } from '@angular/core';
import { DialogVideoComponent } from '../dialog-video/dialog-video.component';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Tile_2022 } from '../videos/videos.component';

@Component({
  selector: 'app-nagradi',
  templateUrl: './nagradi.component.html',
  styleUrls: ['./nagradi.component.css']
})
export class NagradiComponent implements OnInit {

  tiles_2022: Tile_2022[] = [
    {id: 1, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/1.png', video: '../../assets/videos/2022/1.mp4', title: 'Јонузов мост' , participants: 'Петар Узуновски'},
    {id: 2, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/2.png', video: '../../assets/videos/2022/2.mp4', title: 'Велик' , participants: 'Симеон Симеонов, Валентин Качев, инж.Милен Венком'},
    {id: 3, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/3.png', video: '../../assets/videos/2022/3.mp4', title: 'Писмо' , participants: 'Давид Козаров, Магдалена Сайкова'},
    {id: 4, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/4.png', video: '../../assets/videos/2022/4.mp4', title: 'Разстрели' , participants: 'Янко Митев, Димитър Атанасов'},
    {id: 5, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/5.png', video: '../../assets/videos/2022/5.mp4', title: 'Аманет на старите' , participants: 'Tome Ljushev'},
    {id: 6, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/6.png', video: '../../assets/videos/2022/6.mp4', title: 'Сянката' , participants: 'Димитър Атанасов, Валери Цветков, Димитър Атанасов, Деспина Попова-Цветкова'},
    {id: 7, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/7.png', video: '../../assets/videos/2022/7.mp4', title: 'Писмото' , participants: 'Красимир Георгиев, Нико Майеров, Апостол Фанев, Гълъбина Йосифова'},
    {id: 8, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/8.png', video: '../../assets/videos/2022/8.mp4', title: 'Спомен за Величко' , participants: 'Красимир Георгиев, Нико Майеров, Апостол Фанев'},
    {id: 9, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/9.png', video: '../../assets/videos/2022/9.mp4', title: 'блок от 3 филма от 70-те' , participants: 'Милко Милков, Цветан Джолев, Кирчо Жеков, Петко Бобев, Анани Кабакчиев и други'},
    {id: 10, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/10.png', video: '../../assets/videos/2022/10.mp4', title: '24 май - Ден просвета и култура, и славянската писменост' , participants: 'Наум Кулев'},
    {id: 11, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/11.png', video: '../../assets/videos/2022/11.mp4', title: 'Културно-исторически паметници в риск | църквата „Св. Атанас“, с. Косово, общ. Каспичан' , participants: 'Мултимедиен клуб „Ескимо“'},
    {id: 12, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/12.png', video: '../../assets/videos/2022/12.mp4', title: 'Културно-исторически паметници в риск | църквата "Св. Никола", с. Марково, общ. Каспичан' , participants: 'Мултимедиен клуб „Ескимо“'},
    
    {id: 13, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/13.png', video: '../../assets/videos/2022/13.mp4', title: 'Bitola-exactly at noon' , participants: 'Tome Stojcevski, Aleksandar Vajdevski, Aleksandar Vajdevski'},
    {id: 14, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/14.png', video: '../../assets/videos/2022/14.mp4', title: 'Najubava bitola' , participants: 'Давид Стојановски'},
    {id: 15, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/15.png', video: '../../assets/videos/2022/15.mp4', title: 'Водици' , participants: 'Dime Talevski'},
    {id: 16, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/16.png', video: '../../assets/videos/2022/16.mp4', title: 'Азбуки – света около нас' , participants: 'Марияна Тодорова и Славка Савова- педагог'},
    {id: 17, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/17.png', video: '../../assets/videos/2022/17.mp4', title: 'Гарата' , participants: 'Киноклуб "Иван Братанов, Попово"'},
    {id: 18, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/18.png', video: '../../assets/videos/2022/18.mp4', title: 'Летопис в картини' , participants: 'Киноклуб "Мадарски Конник"'},
    {id: 19, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/19.png', video: '../../assets/videos/2022/19.mp4', title: 'Маркери' , participants: 'студио „Клин“ при ПГ по икономика Шумен ръководител Апостол Фанев, Екип : ученици от ПГ по икономика Шумен – Борислава, Полина, Цветелина, Айсел, Станислава и Иван'},

    {id: 20, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/20.png', video: '../../assets/videos/2022/20.mp4', title: 'Съкровището' , participants: 'Пламен Личев, Николай Лазаров, Николай Василев'},
    {id: 21, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/21.png', video: '../../assets/videos/2022/21.mp4', title: 'Пазители на времето' , participants: 'Пламен Личев, Николай Лазаров'},
    {id: 22, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/22.png', video: '../../assets/videos/2022/22.mp4', title: 'Там, откъдето започва България' , participants: 'Николай Василев'},
    {id: 23, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/23.png', video: '../../assets/videos/2022/23.mp4', title: 'Право на живот' , participants: 'Николай Василев'},
    {id: 24, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/24.png', video: '../../assets/videos/2022/24.mp4', title: 'Радио „Бралть“' , participants: 'сценарий – Емилия Петкова, оператор – Николай Лазаров'},
    {id: 25, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/25.png', video: '../../assets/videos/2022/25.mp4', title: 'ХермАфродит' , participants: 'Николай Лазаров'},
    
    {id: 26, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/26.png', video: '../../assets/videos/2022/26.mp4', title: 'Врата' , participants: 'Другаря Добрев'},
    {id: 27, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/27.png', video: '../../assets/videos/2022/27.mp4', title: 'Нити' , participants: 'Данчук Устин'},
    {id: 28, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/28.png', video: '../../assets/videos/2022/28.mp4', title: 'Почему в Херсоне маршрутки работают гораздо хуже чем в Николаеве' , participants: 'Андрей Польшин'},
    {id: 29, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/29.png', video: '../../assets/videos/2022/29.mp4', title: 'Автофотопортрет' , participants: 'Другаря Добрев'},
    {id: 30, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/30.png', video: '../../assets/videos/2022/30.mp4', title: 'Ангел' , participants: 'Наталья Кармазина'},
    {id: 31, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/31.png', video: '../../assets/videos/2022/31.mp4', title: 'Ахтунг Минотавр' , participants: 'Андрей Богун'},
    {id: 32, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/32.png', video: '../../assets/videos/2022/32.mp4', title: 'Българската следа' , participants: 'Антон Жеков'},
    {id: 33, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/33.png', video: '../../assets/videos/2022/33.mp4', title: 'За яблоками' , participants: 'Данчук Устин'},
    {id: 34, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/34.png', video: '../../assets/videos/2022/34.mp4', title: 'Знаци на вярата' , participants: 'Ангел Василев'},
    {id: 35, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/35.png', video: '../../assets/videos/2022/35.mp4', title: 'Испанска епопея' , participants: 'Ангел Василев'},
    {id: 36, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/36.png', video: '../../assets/videos/2022/36.mp4', title: 'Равновесие' , participants: 'Данчук Устин'},
    {id: 37, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/37.png', video: '../../assets/videos/2022/37.mp4', title: 'Хозяин двора' , participants: 'Данчук Устин'},
    {id: 38, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/38.png', video: '../../assets/videos/2022/38.mp4', title: 'Человек Семейный' , participants: 'Данчук Устин'},

    {id: 39, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/39.png', video: '../../assets/videos/2022/39.mp4', title: 'Добричката епопея 1916, Част Първа' , participants: 'Снежана Иванова'},
    {id: 40, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/40.png', video: '../../assets/videos/2022/40.mp4', title: 'Добричката епопея 1916, Част Втора' , participants: 'Снежана Иванова'},
    {id: 41, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/41.png', video: '../../assets/videos/2022/41.mp4', title: 'Кинопамет' , participants: 'Симеон Симеонов, Николина Маричова'},
    {id: 42, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/42.png', video: '../../assets/videos/2022/42.mp4', title: 'Дядо, погледни миналото' , participants: 'Радост Василева'},
    {id: 43, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/43.png', video: '../../assets/videos/2022/43.mp4', title: 'Старо колкото света' , participants: 'Радост Василева'},
    {id: 44, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/44.png', video: '../../assets/videos/2022/44.mp4', title: 'Знаци на вярата' , participants: 'Ангел Василев'},

    {id: 45, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/45.png', video: '../../assets/videos/2022/45.mp4', title: 'Олимпий Панов' , participants: '----- ------'},
  ];

  constructor(private dialog: MatDialog) { }

  ngOnInit() {
  }

  viewVideo(tile) {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = false;

    dialogConfig.data = {
      videoObj: tile,
      close: 'Затвори'
    };

    const dialogRef = this.dialog.open(DialogVideoComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      data => {
      }
    );
  }

}
