import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fr-jury',
  templateUrl: './fr-jury.component.html',
  styleUrls: ['./fr-jury.component.css']
})
export class FrJuryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
