import { Component, OnInit } from '@angular/core';
import * as AOS from 'aos';
import { DialogWarningComponent } from './dialog-warning/dialog-warning.component';
import { MatDialog, MatDialogConfig } from '@angular/material';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  constructor(private dialog: MatDialog) { }

  bg: boolean;
  en: boolean;
  ru: boolean;
  fr: boolean;
  de: boolean;

  ngOnInit(): void {
    AOS.init({
      startEvent: 'load',
    });
    this.showBulgarian();
  }


  showBulgarian() {
    this.bg = true;
    this.en = false;
    this.ru = false;
    this.fr = false;
    this.de = false;
    // this.viewWarningInBG();
  }

  showEnglish() {
    this.bg = false;
    this.en = true;
    this.ru = false;
    this.fr = false;
    this.de = false;
    // this.viewWarningInEN();
  }

  showRussian() {
    this.bg = false;
    this.en = false;
    this.ru = true;
    this.fr = false;
    this.de = false;
    // this.viewWarningInRU();
  }

  showFrench() {
    this.bg = false;
    this.en = false;
    this.ru = false;
    this.fr = true;
    this.de = false;
    // this.viewWarningInFR();
  }

  showGerman() {
    this.bg = false;
    this.en = false;
    this.ru = false;
    this.fr = false;
    this.de = true;
    // this.viewWarningInDE();
  }

  viewWarningInBG() {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      id: '1',
      message: 'Здравейте,  В условията на епидемиологична обстановка и ограничения относно масови културни събития на закрито, организаторите на юбилейния шуменски фестивал на историческите любителски филми взеха решение за отлагане на фестивала. Новата дата на фестивала е 10.10.2020 г.Предвид на неясността относно здравната обстановка в България през есента, предвиждаме селекция на филмите.Информираме ви, че ще очакваме вашите филми до 10.09.2020 г. Журито ще прегледа изпратените филми от 11.09.2020 г. до 24.09.2020 г. Приемете нашите извинения за създадените неудобства. Вземаме тези решения по независещи от нас причини. Продължаваме да работим по организацията на юбилейния кинофестивал в Шумен. Очакваме филмите ви. Бъдете здрави.',
      description: 'ВНИМАНИЕ!',
      bottomOne: 'Н.Ч. „Добри Войников 1856“',
      bottomTwo: 'Киноклуб „Мадарски конник“',
      close: 'Затвори'
    };

    const dialogRef = this.dialog.open(DialogWarningComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      data => {
      }
    );
  }

  viewWarningInEN() {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      id: '2',
      message: 'Hello, In the conditions of epidemiological situation and restrictions on mass cultural events indoors, the organizers of the jubilee Shumen festival of historical amateur films have decided to postpone the festival. The new date of the festival is 10.10.2020. Given the uncertainty about the health situation in Bulgaria in the fall, we envisage a selection of films. We inform you that we will expect your films until 10.09.2020. The jury will review the submitted films from 11.09.2020. Until September 24, 2020. Accept our apologies for the inconvenience. We make these decisions for reasons beyond our control. We continue to work on the organization of the jubilee film festival in Shumen. We are waiting for your movies. Be healthy.',
      description: 'ATTENTION!',
      bottomOne: '„ Dobri Voynikov 1856 “ Community Center ',
      bottomTwo: 'Film Club „ Madarski Konnik “',
      close: 'Close'
    };

    const dialogRef = this.dialog.open(DialogWarningComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      data => {
      }
    );
  }

  viewWarningInRU() {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      id: '3',
      message: 'Здравствуйте, В условиях эпидемиологической обстановки и ограничений на массовые культурные мероприятия в помещении организаторы юбилейного Шуменского фестиваля исторических любительских фильмов решили перенести фестиваль. Новая дата фестиваля - 10.10.2020 г. Учитывая неопределенность ситуации со здоровьем в Болгарии осенью, мы предполагаем подборку фильмов. Сообщаем, что ваши фильмы будут ждать до 10.09.2020 г. Жюри рассмотрит представленные фильмы с 11.09.2020. До 24 сентября 2020 года. Примите наши извинения за доставленные неудобства. Мы принимаем эти решения по независящим от нас причинам. Мы продолжаем работать над организацией юбилейного кинофестиваля в Шумене. Мы ждем ваших фильмов. Быть здоровым.',
      description: 'ВНИМАНИЕ!',
      bottomOne: 'Н.Ч. „Добри Войников 1856“',
      bottomTwo: 'Киноклуб „Мадарски конник“',
      close: 'Закрыть'
    };

    const dialogRef = this.dialog.open(DialogWarningComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      data => {
      }
    );
  }

  viewWarningInFR() {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      id: '4',
      message: 'Bonjour, Dans les conditions de situation épidémiologique et de restrictions des événements culturels de masse à l“intérieur, les organisateurs du festival jubilé de films amateurs historiques ont décidé de reporter le festival. La nouvelle date du festival est le 10 octobre 2020. Compte tenu de l“incertitude sur la situation sanitaire en Bulgarie à l“automne, nous vous proposons une sélection de films. Nous vous informons que nous attendrons vos films jusqu“au 10 septembre 2020. Le jury examinera les films soumis à partir du 11 septembre 2020. Jusqu“au 24 septembre 2020. Acceptez nos excuses pour la gêne occasionnée. Nous prenons ces décisions pour des raisons indépendantes de notre volonté. Nous continuons de travailler à l“organisation du festival du film du jubilé à Shumen. Nous attendons vos films avec impatience. Être en bonne santé.',
      description: 'ATTENTION!',
      bottomOne: 'Centre Communautaire „Dobri Voĭnikov 1856“',
      bottomTwo: 'Film Club „ Madarski Konnik “',
      close: 'Fermer'
    };

    const dialogRef = this.dialog.open(DialogWarningComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      data => {
      }
    );
  }

  viewWarningInDE() {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      id: '5',
      message: 'Hallo, unter den Bedingungen der epidemiologischen Situation und der Einschränkungen von kulturellen Massenveranstaltungen in Innenräumen haben die Organisatoren des Jubiläums-Shumen-Festivals für historische Amateurfilme beschlossen, das Festival zu verschieben. Der neue Termin für das Festival ist der 10.10.2020. Angesichts der Unsicherheit über die gesundheitliche Situation in Bulgarien im Herbst planen wir eine Auswahl von Filmen. Wir teilen Ihnen mit, dass wir Ihre Filme bis zum 10.09.2020 erwarten. Die Jury wird die eingereichten Filme ab dem 11.09.2020 prüfen. Bis zum 24. September 2020. Wir entschuldigen uns für die Unannehmlichkeiten. Wir treffen diese Entscheidungen aus Gründen, die außerhalb unserer Kontrolle liegen. Wir arbeiten weiter an der Organisation des Jubiläumsfilmfestivals in Shumen. Wir warten auf Ihre Filme. Gesund sein.',
      description: 'BEACHTUNG!',
      bottomOne: 'Volks Gemeinschaftszentrum „Dobri Voĭnikov 1856“',
      bottomTwo: 'Filmclub „ Madarski Konnik “',
      close: 'Schließen'
    };

    const dialogRef = this.dialog.open(DialogWarningComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      data => {
      }
    );
  }

}
