import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatDialogConfig, ThemePalette } from "@angular/material";

@Component({
  selector: 'app-dialog-warning',
  templateUrl: './dialog-warning.component.html',
  styleUrls: ['./dialog-warning.component.css']
})
export class DialogWarningComponent implements OnInit {

  id: number;
  description: string;
  message: string;
  bottomOne: string;
  bottomTwo: string;
  close: string;

  constructor(private dialogRef: MatDialogRef<DialogWarningComponent>, @Inject(MAT_DIALOG_DATA) data: any) {
    this.id = data.id;
    this.description = data.description;
    this.message = data.message;
    this.bottomOne = data.bottomOne;
    this.bottomTwo = data.bottomTwo;
    this.close = data.close;
   }

  ngOnInit() {
  }

}
