import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fr-contacts',
  templateUrl: './fr-contacts.component.html',
  styleUrls: ['./fr-contacts.component.css']
})
export class FrContactsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
