import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EnAppComponent } from './en-app/en-app.component';

const routes: Routes = [
  {
  path: '#en', 
  component: EnAppComponent,
},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const routingComponents = [EnAppComponent]