import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FormService {

  constructor(private http: HttpClient) { }

  createNewParticipant(form: any) {
    const data = form;
    
    return this.http.post<any>('https://shumenfest.eu/php/InsertForm.php', data);
    // return this.http.post<any>('http://dev.shumenfest.eu/php/InsertForm.php', data);
  }

  sendMessageToPip(message: string) {
    const data = message;

    //Production BOT
    return this.http.post<any>('https://api.telegram.org/bot900740424:AAE4G7rRxdFEva7fXfeNgjzmZZfaBbA2Cm4/sendMessage?chat_id=-436948994&text=' + encodeURIComponent(data), data);
    //Development BOT
    // return this.http.post<any>('https://api.telegram.org/bot991067138:AAHwpT2e566njqpVlxWE3hVZ6zwwVIUSkHc/sendMessage?chat_id=-324295077&text=' + encodeURIComponent(data), data);
  }

}
