import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DialogVideoComponent } from '../dialog-video/dialog-video.component';
import { MatDialog, MatDialogConfig } from '@angular/material';

export interface Tile {
  color: string;
  cols: number;
  rows: number;
  id: number;
  video: string;
  title: string;
  participants: string;
}

export interface Tile_2022 {
  color: string;
  cols: number;
  rows: number;
  id: number;
  image: string;
  video: string;
  title: string;
  participants: string;
}

@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class VideosComponent implements OnInit {

  img = ""

  tiles: Tile[] = [

    {id: 1, cols: 1, rows: 1, color: 'white', video: '../../assets/videos/1.mp4', title: 'A VIEW TO THE PAST' , participants: 'Ния-Василена Станимирова, Александра Александрова, Бранимир Николов, Рая Николова'},
    {id: 2, cols: 1, rows: 1, color: 'white', video: '../../assets/videos/2.mp4', title: 'ДОРОЖНИЙ РОМАН' , participants: 'Александр Елагин, Виктор Быкодир, Павел Зуб, Елена Кудышкина, Екатерина Елагина, Олег Субботин, Владимир Столяр, Надежда Лебедева'},
    {id: 3, cols: 1, rows: 1, color: 'white', video: '../../assets/videos/3.mp4', title: 'ВСЕ ВОЗРАСТЫ ПОКОРНЫ ' , participants: 'Александр Елагин, Виктор Быкодир, Наталья Беланенко, Надежда Лебедева'},
    {id: 4, cols: 1, rows: 1, color: 'white', video: '../../assets/videos/4.mp4', title: 'РОДИНА, МОЯ РОДИНА ' , participants: 'Александр Елагин, Виктор Быкодир, Надежда Лебедева, Наталья Беланенко, Анастасия Лещенко'},
    {id: 5, cols: 1, rows: 1, color: 'white', video: '../../assets/videos/5.mp4', title: 'ЧЁРНАЯ ПТИЦА С БЕЛОЙ ОТМЕТИНОЙ' , participants: 'Виктор Быкодир, Александр Елагин, Андрей Бегун, Владимир Муравицкий'},
    {id: 6, cols: 1, rows: 1, color: 'white', video: '../../assets/videos/6.mp4', title: 'СВЕТЛАТА СЯНКА НА ТАЛАНТА' , participants: 'Владимир Илиев'},
    {id: 7, cols: 1, rows: 1, color: 'white', video: '../../assets/videos/7.mp4', title: 'КРАЛСКА СЛЕДА' , participants: 'Владимир Илиев, Николай Василев'},
    {id: 8, cols: 1, rows: 1, color: 'white', video: '../../assets/videos/8.mp4', title: 'МИЛИОН ЧЕРВЕНИ РОЗИ' , participants: 'Владимир Илиев'},
    {id: 9, cols: 1, rows: 1, color: 'white', video: '../../assets/videos/9.mp4', title: 'СПОМЕН ЗА ОТМИНАЛОТО БЪДЕЩЕ' , participants: 'Владимир Илиев'},
    {id: 10, cols: 1, rows: 1, color: 'white', video: '../../assets/videos/10.mp4', title: 'БАСАРБОВСКИЯТ СКАЛЕН МАНАСТИР' , participants: 'Владимир Илиев, Николай Василев, Милчо Йовчев'},
    {id: 11, cols: 1, rows: 1, color: 'white', video: '../../assets/videos/11.mp4', title: 'FROM GLORY TO MISERY' , participants: 'Петар Узуновски'},
    {id: 12, cols: 1, rows: 1, color: 'white', video: '../../assets/videos/12.mp4', title: 'THE SINEMAS IN STRUGA' , participants: 'Петар Узуновски'},
    {id: 13, cols: 1, rows: 1, color: 'white', video: '../../assets/videos/13.mp4', title: 'СТРУШКИОТ ДЕДО МРАЗ ' , participants: 'Петар Узуновски'},
    {id: 14, cols: 1, rows: 1, color: 'white', video: '../../assets/videos/14.mp4', title: 'ONCE ON ZIRIKH LAKE SHORE' , participants: 'Boris Glashev, Ilias Sozaev, Dzhamilya Kaychueva'},
    {id: 15, cols: 1, rows: 1, color: 'white', video: '../../assets/videos/15.mp4', title: 'RAMADAN' , participants: 'Rolf Mandolesi'},
    {id: 16, cols: 1, rows: 1, color: 'white', video: '../../assets/videos/16.mp4', title: 'ЛАМЯ', participants: 'Радостина Дулева, Тихомир Дулев, Георги Дулев, Калоян Дулев, Лиляна Дулева, Катерина Тодорова, Методи Терзийски'},
    {id: 17, cols: 1, rows: 1, color: 'white', video: '../../assets/videos/17.mp4', title: 'HOLOTECTURE', participants: 'Тихомир Дулев, Георги Дулев, Даниел Димитров, Александър Негревски'},
    {id: 18, cols: 1, rows: 1, color: 'white', video: '../../assets/videos/18.mp4', title: 'OD TRPEJCA PREKU SV ZAUM DO SV NAUM', participants: 'Љушев Томе Шпанец'},
    {id: 19, cols: 1, rows: 1, color: 'white', video: '../../assets/videos/19.mp4', title: 'PTUJ   ANG POD (Птуј-место музеј и традиција)', participants: 'Anita Kirbis'},
    {id: 20, cols: 1, rows: 1, color: 'white', video: '../../assets/videos/20.mp4', title: 'THAI LIVE', participants: 'Rolf Mandolesi'},
    {id: 21, cols: 1, rows: 1, color: 'white', video: '../../assets/videos/21.mp4', title: 'БЕЛОГРАДЧИК И ХОРАТА 2020', participants: 'Симеон Симеонов, Николина Маричова, Милен Велков, Валери Цветков.'},
    {id: 22, cols: 1, rows: 1, color: 'white', video: '../../assets/videos/22.mp4', title: 'БОЖДОВО ФИЛИ 2020 (СЕЛО БОЖДОВО)', participants: 'Спиро Борисов'},
    {id: 23, cols: 1, rows: 1, color: 'white', video: '../../assets/videos/23.mp4', title: 'КОРЕНИЩЕ 2017 (КОРЕНИЩЕ)', participants: 'Спиро Борисов'},
    {id: 24, cols: 1, rows: 1, color: 'white', video: '../../assets/videos/24.mp4', title: 'BIG LAKE', participants: 'Pantelejmon Trpcevski'},
    {id: 25, cols: 1, rows: 1, color: 'white', video: '../../assets/videos/25.mp4', title: 'ПАНТЕЛЕЈМОН ТРПЧЕВСКИ - ИСКАЧИ ГО ПЕЛИСТЕР(ИСКАЧИ ГО ПЕЛИСТЕР(АНГ. HIGH PELISTER)', participants: 'Пантелејмон Трпчевски'},
    {id: 26, cols: 1, rows: 1, color: 'white', video: '../../assets/videos/26.mp4', title: 'PETRE - LJUBOV, BOL I NEPREBOL (ПЕТРЕ - ЉУБОВ, БОЛ И НЕПРЕБОЛ)', participants: 'Филип  Илиевски, Сара Стојановска'},
    {id: 27, cols: 1, rows: 1, color: 'white', video: '../../assets/videos/27.mp4', title: 'SCULPTURE (SCULPTURE) ', participants: 'Franc Kopic'},
    {id: 28, cols: 1, rows: 1, color: 'white', video: '../../assets/videos/28.mp4', title: 'ЗАВЕТЪТ НА КРАКРА', participants: 'Арт колеж София Слав Едрев'},
    {id: 29, cols: 1, rows: 1, color: 'white', video: '../../assets/videos/29.mp4', title: 'ЛЪВОВ МОСТ - В ПАМЕТ НА КНИЖАРИТЕ ', participants: 'Арт колеж София Слав Едрев'},
    {id: 30, cols: 1, rows: 1, color: 'white', video: '../../assets/videos/30.mp4', title: 'ОРЛОВ МОСТ', participants: 'Арт колеж София Слав Едрев'},
    {id: 31, cols: 1, rows: 1, color: 'white', video: '../../assets/videos/31.mp4', title: 'ЛИТИЈА-A RELIGION PROCESS-7.28 MIN (ЛИТИЈА (АНГ. RELIGION PROCESS)', participants: 'Диме Талевски'},
    {id: 32, cols: 1, rows: 1, color: 'white', video: '../../assets/videos/32.mp4', title: 'KANJON JERMA-2.32 MIN (КАЊОН ЈЕРМА ( АНГ.THE KANNON JERMA) ', participants: 'Диме Талевски'},
    {id: 33, cols: 1, rows: 1, color: 'white', video: '../../assets/videos/33.mp4', title: 'ЗАЕДНО СО РЗЛИКИ БЕЗ ПРЕДРАСУДИ (TOGETHER WITH DIVERSITIES, WITHOUT PREJUDICE)', participants: 'Кристиян Кочовски'},
    {id: 34, cols: 1, rows: 1, color: 'white', video: '../../assets/videos/34.mp4', title: 'ДА ЈА ЗАЧУВАМЕ НАШАТА ПЛАНЕТА (PROTECT OUR PLANET)', participants: 'Кристиян Кочовски'},
    {id: 35, cols: 1, rows: 1, color: 'white', video: '../../assets/videos/35.mp4', title: 'СЛУЧВА СЕ… (HAPPENS)', participants: 'студио „Клин“ при ПГИ-Шумен, ръководител Апостол Фанев.'},
    {id: 36, cols: 1, rows: 1, color: 'white', video: '../../assets/videos/36.mp4', title: 'НАПРАВИ СИ САМ ФИЛМ', participants: 'Нико Майеров'},
    {id: 37, cols: 1, rows: 1, color: 'white', video: '../../assets/videos/37.mp4', title: 'НЕЗАБРАВЕНИТЕ ХАРМОНИКИ', participants: 'Величко Геров, Владимир Лечев, Жулиета Герова, Красимир Георгиев'},
    {id: 38, cols: 1, rows: 1, color: 'white', video: '../../assets/videos/38.mp4', title: 'ХИМНЪТ НА ШУМЕН', participants: 'Апостол Фанев, Величко Геров, Жулиета Герова, Красимир Георгиев, Нико Майеров'},
    {id: 39, cols: 1, rows: 1, color: 'white', video: '../../assets/videos/39.mp4', title: 'ЧОВЕКЪТ И НЕГОВОТО ВРЕМЕ', participants: 'Апостол Фанев, Величко Геров, Жулиета Герова, Красимир Георгиев, Нико Майеров'},
    {id: 40, cols: 1, rows: 1, color: 'white', video: '../../assets/videos/40.mp4', title: 'БЕРКВИТИС', participants: 'Валериан Петров, Евстати Илиев'},
    {id: 41, cols: 1, rows: 1, color: 'white', video: '../../assets/videos/41.mp4', title: 'DERMATOP', participants: 'Димитър Атанасов, Румен Христов'},
    {id: 42, cols: 1, rows: 1, color: 'white', video: '../../assets/videos/42.mp4', title: 'PARUSMAJOR', participants: 'Димитър Атанасов, Янко Митев, Димитър Русев,Петя Ноева'},
    {id: 43, cols: 1, rows: 1, color: 'white', video: '../../assets/videos/43.mp4', title: 'ГИМНАЗИЯТА', participants: 'Апостол Фанев, Наум Кулев, Рени Векова'},
    {id: 44, cols: 1, rows: 1, color: 'white', video: '../../assets/videos/44.mp4', title: 'COVENTRY_ANTON (КОВЪНТРИ)', participants: 'Антон Жеков'},
    {id: 45, cols: 1, rows: 1, color: 'white', video: '../../assets/videos/45.mp4', title: 'BAE PENCHO (БАЕ ПЕНЧО)', participants: 'Петър Рангелов, Десислава Златева, Иван Пенчев, Денчо Бучев'},
    {id: 46, cols: 1, rows: 1, color: 'white', video: '../../assets/videos/46.mp4', title: 'KALINKATA', participants: 'Александър Атанасов, Димитър Атанасов, Янко Митев'}

  ];
  /*
    tiles_2022: Tile_2022[] = [

      {id: 1, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/1.png', video: '../../assets/videos/2022/1.mp4', title: 'Јонузов мост' , participants: 'Петар Узуновски'},
      {id: 2, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/2.png', video: '../../assets/videos/2022/2.mp4', title: 'Велик' , participants: 'Симеон Симеонов, Валентин Качев, инж.Милен Венком'},
      {id: 3, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/3.png', video: '../../assets/videos/2022/3.mp4', title: 'Писмо' , participants: 'Давид Козаров, Магдалена Сайкова'},
      {id: 4, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/4.png', video: '../../assets/videos/2022/4.mp4', title: 'Разстрели' , participants: 'Янко Митев, Димитър Атанасов'},
      {id: 5, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/5.png', video: '../../assets/videos/2022/5.mp4', title: 'Аманет на старите' , participants: 'Tome Ljushev'},
      {id: 6, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/6.png', video: '../../assets/videos/2022/6.mp4', title: 'Сянката' , participants: 'Димитър Атанасов, Валери Цветков, Димитър Атанасов, Деспина Попова-Цветкова'},
      {id: 7, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/7.png', video: '../../assets/videos/2022/7.mp4', title: 'Писмото' , participants: 'Красимир Георгиев, Нико Майеров, Апостол Фанев, Гълъбина Йосифова'},
      {id: 8, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/8.png', video: '../../assets/videos/2022/8.mp4', title: 'Спомен за Величко' , participants: 'Красимир Георгиев, Нико Майеров, Апостол Фанев'},
      {id: 9, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/9.png', video: '../../assets/videos/2022/9.mp4', title: 'блок от 3 филма от 70-те' , participants: 'Милко Милков, Цветан Джолев, Кирчо Жеков, Петко Бобев, Анани Кабакчиев и други'},
      {id: 10, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/10.png', video: '../../assets/videos/2022/10.mp4', title: '24 май - Ден просвета и култура, и славянската писменост' , participants: 'Наум Кулев'},
      {id: 11, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/11.png', video: '../../assets/videos/2022/11.mp4', title: 'Културно-исторически паметници в риск | църквата „Св. Атанас“, с. Косово, общ. Каспичан' , participants: 'Мултимедиен клуб „Ескимо“'},
      {id: 12, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/12.png', video: '../../assets/videos/2022/12.mp4', title: 'Културно-исторически паметници в риск | църквата "Св. Никола", с. Марково, общ. Каспичан' , participants: 'Мултимедиен клуб „Ескимо“'},

      {id: 13, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/13.png', video: '../../assets/videos/2022/13.mp4', title: 'Bitola-exactly at noon' , participants: 'Tome Stojcevski, Aleksandar Vajdevski, Aleksandar Vajdevski'},
      {id: 14, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/14.png', video: '../../assets/videos/2022/14.mp4', title: 'Najubava bitola' , participants: 'Давид Стојановски'},
      {id: 15, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/15.png', video: '../../assets/videos/2022/15.mp4', title: 'Водици' , participants: 'Dime Talevski'},
      {id: 16, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/16.png', video: '../../assets/videos/2022/16.mp4', title: 'Азбуки – света около нас' , participants: 'Марияна Тодорова и Славка Савова- педагог'},
      {id: 17, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/17.png', video: '../../assets/videos/2022/17.mp4', title: 'Гарата' , participants: 'Киноклуб "Иван Братанов, Попово"'},
      {id: 18, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/18.png', video: '../../assets/videos/2022/18.mp4', title: 'Летопис в картини' , participants: 'Киноклуб "Мадарски Конник"'},
      {id: 19, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/19.png', video: '../../assets/videos/2022/19.mp4', title: 'Маркери' , participants: 'студио „Клин“ при ПГ по икономика Шумен ръководител Апостол Фанев, Екип : ученици от ПГ по икономика Шумен – Борислава, Полина, Цветелина, Айсел, Станислава и Иван'},

      {id: 20, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/20.png', video: '../../assets/videos/2022/20.mp4', title: 'Съкровището' , participants: 'Пламен Личев, Николай Лазаров, Николай Василев'},
      {id: 21, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/21.png', video: '../../assets/videos/2022/21.mp4', title: 'Пазители на времето' , participants: 'Пламен Личев, Николай Лазаров'},
      {id: 22, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/22.png', video: '../../assets/videos/2022/22.mp4', title: 'Там, откъдето започва България' , participants: 'Николай Василев'},
      {id: 23, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/23.png', video: '../../assets/videos/2022/23.mp4', title: 'Право на живот' , participants: 'Николай Василев'},
      {id: 24, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/24.png', video: '../../assets/videos/2022/24.mp4', title: 'Радио „Бралть“' , participants: 'сценарий – Емилия Петкова, оператор – Николай Лазаров'},
      {id: 25, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/25.png', video: '../../assets/videos/2022/25.mp4', title: 'ХермАфродит' , participants: 'Николай Лазаров'},

      {id: 26, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/26.png', video: '../../assets/videos/2022/26.mp4', title: 'Врата' , participants: 'Другаря Добрев'},
      {id: 27, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/27.png', video: '../../assets/videos/2022/27.mp4', title: 'Нити' , participants: 'Данчук Устин'},
      {id: 28, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/28.png', video: '../../assets/videos/2022/28.mp4', title: 'Почему в Херсоне маршрутки работают гораздо хуже чем в Николаеве' , participants: 'Андрей Польшин'},
      {id: 29, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/29.png', video: '../../assets/videos/2022/29.mp4', title: 'Автофотопортрет' , participants: 'Другаря Добрев'},
      {id: 30, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/30.png', video: '../../assets/videos/2022/30.mp4', title: 'Ангел' , participants: 'Наталья Кармазина'},
      {id: 31, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/31.png', video: '../../assets/videos/2022/31.mp4', title: 'Ахтунг Минотавр' , participants: 'Андрей Богун'},
      {id: 32, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/32.png', video: '../../assets/videos/2022/32.mp4', title: 'Българската следа' , participants: 'Антон Жеков'},
      {id: 33, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/33.png', video: '../../assets/videos/2022/33.mp4', title: 'За яблоками' , participants: 'Данчук Устин'},
      {id: 34, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/34.png', video: '../../assets/videos/2022/34.mp4', title: 'Знаци на вярата' , participants: 'Ангел Василев'},
      {id: 35, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/35.png', video: '../../assets/videos/2022/35.mp4', title: 'Испанска епопея' , participants: 'Ангел Василев'},
      {id: 36, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/36.png', video: '../../assets/videos/2022/36.mp4', title: 'Равновесие' , participants: 'Данчук Устин'},
      {id: 37, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/37.png', video: '../../assets/videos/2022/37.mp4', title: 'Хозяин двора' , participants: 'Данчук Устин'},
      {id: 38, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/38.png', video: '../../assets/videos/2022/38.mp4', title: 'Человек Семейный' , participants: 'Данчук Устин'},

      {id: 39, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/39.png', video: '../../assets/videos/2022/39.mp4', title: 'Добричката епопея 1916, Част Първа' , participants: 'Снежана Иванова'},
      {id: 40, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/40.png', video: '../../assets/videos/2022/40.mp4', title: 'Добричката епопея 1916, Част Втора' , participants: 'Снежана Иванова'},
      {id: 41, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/41.png', video: '../../assets/videos/2022/41.mp4', title: 'Кинопамет' , participants: 'Симеон Симеонов, Николина Маричова'},
      {id: 42, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/42.png', video: '../../assets/videos/2022/42.mp4', title: 'Дядо, погледни миналото' , participants: 'Радост Василева'},
      {id: 43, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/43.png', video: '../../assets/videos/2022/43.mp4', title: 'Старо колкото света' , participants: 'Радост Василева'},
      {id: 44, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/44.png', video: '../../assets/videos/2022/44.mp4', title: 'Знаци на вярата' , participants: 'Ангел Василев'},

      {id: 45, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2022/45.png', video: '../../assets/videos/2022/45.mp4', title: 'Олимпий Панов!' , participants: '----- ------'},

  ];
 */

  tiles_2024: Tile_2022[] = [

    {id: 1, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2024/1.png', video: '../../assets/videos/2024/1.mp4', title: 'Времето на Войников' , participants: ''},
    {id: 2, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2024/2.png', video: '../../assets/videos/2024/2.mp4', title: 'Спомен за Тодор Колев' , participants: ''},
    {id: 3, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2024/3.png', video: '../../assets/videos/2024/3.mp4', title: 'Дирите на българщината в Одрин!' , participants: 'test'},
    /*{id: 4, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2024/4.png', video: '../../assets/videos/2024/4.mp4', title: 'Далече от нас' , participants: ''},*/
    /*{id: 5, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2024/5.png', video: '../../assets/videos/2024/5.mp4', title: 'Може би' , participants: ''},*/
    {id: 6, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2024/6.jpg', video: '../../assets/videos/2024/6.mp4', title: 'EMPORIUM-ДУШАТА НА ГРАДОТ' , participants: 'ХРИСТИЈАН КОЧОВСКИ'},
    {id: 7, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2024/7.jpg', video: '../../assets/videos/2024/7.mp4', title: 'ПОВРАТНИКОТ' , participants: 'Диме Талевски'},
    {id: 8, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2024/8.jpg', video: '../../assets/videos/2024/8.mp4', title: 'ОБИКНОВЕН ГЕРОЙ - част 1' , participants: 'Валери Добрев'},
    {id: 9, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2024/9.jpg', video: '../../assets/videos/2024/9.mp4', title: 'ОБИКНОВЕН ГЕРОЙ - част 2' , participants: 'Валери Добрев'},
    {id: 10, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2024/10.jpg', video: '../../assets/videos/2024/10.mp4', title: 'ТРУДЪТ Е ПЕСЕН ' , participants: 'Валери Добрев'},
    /* {id: 11, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2024/11.jpg', video: '../../assets/videos/2024/11.mp4', title: 'Парче ' , participants: 'Валери Добрев'}, */
    {id: 12, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2024/12.jpg', video: '../../assets/videos/2024/12.mp4', title: 'Нестинари' , participants: 'Иван Тодоров'},

    {id: 13, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2024/13.jpg', video: '../../assets/videos/2024/13.mp4', title: 'Визитка на Шумен' , participants: 'Апостол Фанев, Мико Майеров, Красимир Георгиев'},
    {id: 14, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2024/14.jpg', video: '../../assets/videos/2024/14.mp4', title: 'Домът' , participants: 'Апостол Фанев, Мико Майеров, Красимир Георгиев, Мюжген Исуф, Николай Тодоров'},
    {id: 15, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2024/15.jpg', video: '../../assets/videos/2024/15.mp4', title: 'На гости в родното село' , participants: 'Апостол Фанев, Мико Майеров, Красимир Георгиев'},
    {id: 16, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2024/16.jpg', video: '../../assets/videos/2024/16.mp4', title: 'Художникът' , participants: 'Апостол Фанев, Мико Майеров, Красимир Георгиев, Николай Калев'},

    {id: 17, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2024/17.jpg', video: '../../assets/videos/2024/17.mp4', title: 'Засега така' , participants: 'Ученици от ПГИ – Шумен, ръководител Апостол Фанев'},
    {id: 18, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2024/18.jpg', video: '../../assets/videos/2024/18.mp4', title: 'Може би!' , participants: 'Ученици от ПГИ – Шумен, ръководител Апостол Фанев'},

    {id: 19, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2024/19.jpg', video: '../../assets/videos/2024/19.mp4', title: 'Клучарот Цако' , participants: 'Risteski Ljupco/Ристески Љупчо'},
    {id: 20, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2024/20.jpg', video: '../../assets/videos/2024/20.mp4', title: 'Градот под Кулите' , participants: 'Risteski Ljupco/Ристески Љупчо'},
    {id: 21, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2024/21.jpg', video: '../../assets/videos/2024/21.mp4', title: 'Последниот Дограмаџија' , participants: 'Risteski Ljupco/Ристески Љупчо'},
    /* --- */
    {id: 22, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2024/22.jpg', video: '../../assets/videos/2024/22.mp4', title: 'ШКОТСКИ ТЕМПЕРАМЕНТ' , participants: 'ШКОТСКИ ТЕМПЕРАМЕНТ'},

    {id: 23, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2024/23.jpg', video: '../../assets/videos/2024/23.mp4', title: 'Българският Йерусалим' , participants: 'Памела Петрова, Светозара Димова, Александра Стоянова'},

    {id: 24, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2024/24.jpg', video: '../../assets/videos/2024/24.mp4', title: 'Мисляча Сила' , participants: 'Денис Зозуля'},

    {id: 25, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2024/25.jpg', video: '../../assets/videos/2024/25.mp4', title: 'The Last Homes of Bargerveen' , participants: 'Nico Westerhof, Marshall Usinger'},

    {id: 26, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2024/26.jpg', video: '../../assets/videos/2024/26.mp4', title: 'ГЛАСЪТ НА КАМБАНИТЕ' , participants: 'Силвия Маринова, Николай Василев'},
    {id: 27, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2024/27.jpg', video: '../../assets/videos/2024/27.mp4', title: 'КАЛЕ ШИРОКОВО' , participants: 'Пламен Личев, Николай Василев'},
    {id: 28, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2024/28.jpg', video: '../../assets/videos/2024/28.mp4', title: 'КЮПСФИЛМ' , participants: 'РУСЕФИЛМ'},
    {id: 29, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2024/29.jpg', video: '../../assets/videos/2024/29.mp4', title: 'РОДИНА – ХОРЪТ НА РУСЕ' , participants: 'Пламен Личев, Емилия Василева'},
    {id: 30, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2024/30.jpg', video: '../../assets/videos/2024/30.mp4', title: 'СНАЙПЕРИСТ' , participants: 'Бранимир Попов'},
    /*{id: 31, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2024/31.jpg', video: '../../assets/videos/2024/31.mp4', title: 'ХЕРМАФРОДИТ' , participants: 'Николай Лазаров'},*/
    {id: 32, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2024/32.jpg', video: '../../assets/videos/2024/32.mp4', title: 'A_moment_of_Sava_Dobroplodnis_life' , participants: 'Валентин Атанасов'},
    /* {id: 33, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2024/33.jpg', video: '../../assets/videos/2024/33.mp4', title: 'The Legend of the knight of the Cards' , participants: 'Tony Jacobs'}, */
    {id: 34, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2024/34.jpg', video: '../../assets/videos/2024/34.mp4', title: 'Тодоровден' , participants: 'Евилина Швидченко'},
    {id: 35, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2024/35.jpg', video: '../../assets/videos/2024/35.mp4', title: 'Балконът' , participants: 'Димитър Атанасов, Янко Митев'},
    {id: 36, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2024/36.jpg', video: '../../assets/videos/2024/36.mp4', title: 'Дом на вярата' , participants: 'Димитър Атанасов, Янко Митев'},
    {id: 37, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2024/37.jpg', video: '../../assets/videos/2024/37.mp4', title: 'Киното „Охридско Езеро“' , participants: 'Љупчо Паункоски'},
    {id: 38, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2024/38.jpg', video: '../../assets/videos/2024/38.mp4', title: 'Лето 1850' , participants: 'Калин Георгиев'},
    {id: 39, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2024/39.jpg', video: '../../assets/videos/2024/39.mp4', title: 'ЛИФТОТ НА БРОДОТ АНДЕРТОН' , participants: 'James Chalmers'},

    /* 16.06.2024 */
    {id: 40, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2024/40.jpg', video: '../../assets/videos/2024/40.mp4', title: 'Жул Верн' , participants: 'Тихомир Дулев'},
    {id: 41, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2024/41.jpg', video: '../../assets/videos/2024/41.mp4', title: 'Един спомен за 9.IX.1944' , participants: 'Иво Бобев'},
    {id: 42, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2024/42.jpg', video: '../../assets/videos/2024/42.mp4', title: 'ФЛОТСКАТА КУЛА' , participants: 'Пламен Личев, Емилия Василева, Николай Лазаров'},

    {id: 43, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2024/43.jpg', video: '../../assets/videos/2024/43.mp4', title: 'НЕУМОРНИЯТ' , participants: 'Валентин Иванов'},
    {id: 44, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2024/44.jpg', video: '../../assets/videos/2024/44.mp4', title: 'Литературная Перемена. Бунин' , participants: 'Головин Александр'},
    {id: 45, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2024/45.jpg', video: '../../assets/videos/2024/45.mp4', title: 'Литературная Перемена. Тургенев' , participants: 'Головин Александр'},
    {id: 46, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2024/46.jpg', video: '../../assets/videos/2024/46.mp4', title: 'Безмълвният свидетел - 1916' , participants: 'Генадий Димитров, Александра Атанасова ,Емил Моновски, Владимир Костадинов, Преслав Ганков'},
    {id: 47, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2024/47.jpg', video: '../../assets/videos/2024/47.mp4', title: 'Елегия За Свободата' , participants: 'Кольо Ст. Колев '},
    {id: 48, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2024/48.jpg', video: '../../assets/videos/2024/48.mp4', title: 'Сънят ми слънчев чака вечността…' , participants: 'Славян Костов'},

    {id: 49, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2024/49.jpg', video: '../../assets/videos/2024/49.mp4', title: 'Царственост и величие. Българските владетели през 14-и век Интервю с дизайнера-Част I' , participants: 'Снежана Иванов'},
    {id: 50, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2024/50.jpg', video: '../../assets/videos/2024/50.mp4', title: 'Царственост и величие. Българските владетели през 14-и век Интервю с дизайнера-Част II' , participants: 'Снежана Иванов'},
    {id: 51, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2024/51.jpg', video: '../../assets/videos/2024/51.mp4', title: 'Неочакван обрат' , participants: 'Снежана Иванов'},
    {id: 52, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2024/52.jpg', video: '../../assets/videos/2024/52.mp4', title: 'Її хліб насущний' , participants: 'Vyacheslav (Slavik) Bihun'},
    {id: 53, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2024/53.jpg', video: '../../assets/videos/2024/53.mp4', title: 'Дума про правосуддя' , participants: 'Vyacheslav (Slavik) Bihun'},
    {id: 54, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2024/54.jpg', video: '../../assets/videos/2024/54.mp4', title: 'Mother. In the Name of the Millions' , participants: 'Vyacheslav (Slavik) Bihun'},

    {id: 55, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2024/55.jpg', video: '../../assets/videos/2024/55.mp4', title: 'Тефтерчето на Левски' , participants: 'Елизабета Казале 15 год. и Александра Казале 13 год.'},

    {id: 56, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2024/56.jpg', video: '../../assets/videos/2024/56.mp4', title: 'Украинската култура в Молдова' , participants: 'Мариана Шмиленко'},

    {id: 57, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2024/57.jpg', video: '../../assets/videos/2024/57.mp4', title: 'Априлското въстание (1876г.)' , participants: 'Калина Бърнева'},
    {id: 58, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2024/58.jpg', video: '../../assets/videos/2024/58.mp4', title: 'Атентатът в "Света Неделя”' , participants: 'Калина Бърнева'},

    {id: 59, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2024/59.jpg', video: '../../assets/videos/2024/59.mp4', title: 'О жизни' , participants: 'Евгений Теплов'},

    {id: 60, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2024/60.jpg', video: '../../assets/videos/2024/60.mp4', title: 'Boellie - Living with Heartache' , participants: 'Daniël Beckers'},

    {id: 61, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2024/61.jpg', video: '../../assets/videos/2024/61.mp4', title: 'Зад кадър ГЕОРГИ КИРЯКОВ' , participants: 'Зад кадър ГЕОРГИ КИРЯКОВ'},

    {id: 62, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2024/62.jpg', video: '../../assets/videos/2024/62.mp4', title: 'Безсмъртие в камък' , participants: ''},
    {id: 63, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2024/63.jpg', video: '../../assets/videos/2024/63.mp4', title: 'Загадката на златните мъниста' , participants: ''},
    {id: 64, cols: 1, rows: 1, color: 'white', image: '../../assets/image/2024/64.jpg', video: '../../assets/videos/2024/64.mp4', title: 'Кутия за мечти' , participants: ''},

  ];

  constructor(private dialog: MatDialog) { }

  ngOnInit() {
  }


  viewVideo(tile) {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = false;

    dialogConfig.data = {
      videoObj: tile,
      close: 'Затвори'
    };

    const dialogRef = this.dialog.open(DialogVideoComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      data => {
      }
    );
  }

}
