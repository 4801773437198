import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule, routingComponents } from './app-routing.module';
import { AppComponent } from './app.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule, } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { StatusComponent } from './status/status.component';
import { FormComponent } from './form/form.component';
import { IntroComponent } from './intro/intro.component';
import { JuryComponent } from './jury/jury.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ContactsComponent } from './contacts/contacts.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClientModule } from '@angular/common/http';
import { MatSnackBarModule } from '@angular/material/snack-bar';
// import { RouterModule, Routes } from '@angular/router';
import { EnAppComponent } from './en-app/en-app.component';
import { EnIntroComponent } from './en-intro/en-intro.component';
import { EnStatusComponent } from './en-status/en-status.component';
import { EnFormComponent } from './en-form/en-form.component';
import { EnJuryComponent } from './en-jury/en-jury.component';
import { EnContactsComponent } from './en-contacts/en-contacts.component';
import { RuIntroComponent } from './ru-intro/ru-intro.component';
import { RuFormComponent } from './ru-form/ru-form.component';
import { RuStatutComponent } from './ru-statut/ru-statut.component';
import { RuJuryComponent } from './ru-jury/ru-jury.component';
import { RuContactsComponent } from './ru-contacts/ru-contacts.component';
import { FrContactsComponent } from './fr-contacts/fr-contacts.component';
import { FrFormComponent } from './fr-form/fr-form.component';
import { FrIntroComponent } from './fr-intro/fr-intro.component';
import { FrJuryComponent } from './fr-jury/fr-jury.component';
import { FrStatutComponent } from './fr-statut/fr-statut.component';
import { DeContactsComponent } from './de-contacts/de-contacts.component';
import { DeFormComponent } from './de-form/de-form.component';
import { DeIntroComponent } from './de-intro/de-intro.component';
import { DeJuryComponent } from './de-jury/de-jury.component';
import { DeStatutComponent } from './de-statut/de-statut.component';
import { MatDialogModule } from '@angular/material/dialog';
import { DialogWarningComponent } from './dialog-warning/dialog-warning.component';
import { VideosComponent } from './videos/videos.component';
import { EnVideosComponent } from './en-videos/en-videos.component';
import { DeVideosComponent } from './de-videos/de-videos.component';
import { RuVideosComponent } from './ru-videos/ru-videos.component';
import { FrVideosComponent } from './fr-videos/fr-videos.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DialogVideoComponent } from './dialog-video/dialog-video.component';
import { NagradiComponent } from './nagradi/nagradi.component';
import { EnNagradiComponent } from './en-nagradi/en-nagradi.component';
import { FrNagradiComponent } from './fr-nagradi/fr-nagradi.component';
import { RuNagradiComponent } from './ru-nagradi/ru-nagradi.component';
import { DeNagradiComponent } from './de-nagradi/de-nagradi.component';

// const appRoutes: Routes = [
//   {
//     path: '', 
//     component: AppComponent 
//   },
//   {
//     path: 'en', 
//     component: EnAppComponent 
//   },
//   ];

@NgModule({
  declarations: [
    AppComponent,
    StatusComponent,
    FormComponent,
    IntroComponent,
    JuryComponent,
    ContactsComponent,
    routingComponents,
    EnIntroComponent,
    EnStatusComponent,
    EnFormComponent,
    EnJuryComponent,
    EnContactsComponent,
    RuIntroComponent,
    RuFormComponent,
    RuStatutComponent,
    RuJuryComponent,
    RuContactsComponent,
    FrContactsComponent,
    FrFormComponent,
    FrIntroComponent,
    FrJuryComponent,
    FrStatutComponent,
    DeContactsComponent,
    DeFormComponent,
    DeIntroComponent,
    DeJuryComponent,
    DeStatutComponent,
    DialogWarningComponent,
    VideosComponent,
    EnVideosComponent,
    DeVideosComponent,
    RuVideosComponent,
    FrVideosComponent,
    DialogVideoComponent,
    NagradiComponent,
    EnNagradiComponent,
    FrNagradiComponent,
    RuNagradiComponent,
    DeNagradiComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatMenuModule,
    MatExpansionModule,
    MatIconModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatButtonModule,
    MatDividerModule,
    MatListModule,
    MatAutocompleteModule,
    MatSelectModule,
    ReactiveFormsModule,
    FormsModule,
    MatCheckboxModule,
    MatCardModule,
    FontAwesomeModule,
    HttpClientModule,
    FlexLayoutModule,
    MatSnackBarModule,
    AppRoutingModule,
    MatDialogModule,
    MatGridListModule,
    MatTabsModule,
    MatTooltipModule
    // RouterModule.forRoot(appRoutes,{ enableTracing: true })
  ],
  entryComponents: [DialogWarningComponent, DialogVideoComponent],
  providers: [MatDatepickerModule, MatNativeDateModule, MatFormFieldModule],
  bootstrap: [AppComponent]
})
export class AppModule { }
