import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fr-statut',
  templateUrl: './fr-statut.component.html',
  styleUrls: ['./fr-statut.component.css']
})
export class FrStatutComponent implements OnInit {

  constructor() { }
  step = -1;

  ngOnInit() {
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }


}
