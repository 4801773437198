import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-en-status',
  templateUrl: './en-status.component.html',
  styleUrls: ['./en-status.component.css']
})
export class EnStatusComponent implements OnInit {
  constructor() { }
  step = -1;

  ngOnInit() {
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

}
