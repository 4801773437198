import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormGroupDirective, FormControl, NgForm } from '@angular/forms';
import { ParticipantData } from '../interfaces/participant-data';
import { FormService } from '../services/form.service';
import { SnackbarService } from '../services/snackbar.service';

@Component({
  selector: 'app-fr-form',
  templateUrl: './fr-form.component.html',
  styleUrls: ['./fr-form.component.css']
})
export class FrFormComponent implements OnInit {
  @ViewChild('resetForm', {
    static: false
  }) resetForm: NgForm;

  constructor(private formBuilder: FormBuilder, private data: FormService, private snackBar: SnackbarService) { }

  checked = false;
  checked2 = false;
  checked3 = false;

  createParticipantForm: FormGroup;
  participantchecked1: boolean;
  participantchecked2: boolean;
  participantchecked3: boolean;
  participantStayDateFrom: number = 0;
  participantStayDateTo: number = 0;

  message: string = '';

  stay: boolean;

  ngOnInit() {
    this.createForms();
  }

  get f() { return this.createParticipantForm.controls; }

  onCreateParticipantForm() {

    let participant = {
      participantTitle: this.createParticipantForm.controls.participantTitle.value,
      participantTime: this.createParticipantForm.controls.participantTime.value,
      participantFormatId: this.createParticipantForm.controls.participantFormatId.value,
      participantAnotation: this.createParticipantForm.controls.participantAnotation.value,
      participantVideo: this.createParticipantForm.controls.participantVideo.value,
      participantPostSend: this.createParticipantForm.controls.participantPostSend.value,
      participantAuthor: this.createParticipantForm.controls.participantAuthor.value,
      participantAge: this.createParticipantForm.controls.participantAge.value,
      participantClub: this.createParticipantForm.controls.participantClub.value,
      participantCountry: this.createParticipantForm.controls.participantCountry.value,
      participantCity: this.createParticipantForm.controls.participantCity.value,
      participantAddress: this.createParticipantForm.controls.participantAddress.value,
      participantEmail: this.createParticipantForm.controls.participantEmail.value,
      participantPhone: this.createParticipantForm.controls.participantPhone.value,
      participantchecked1: this.createParticipantForm.controls.participantchecked1.value,
      participantchecked2: this.createParticipantForm.controls.participantchecked2.value,
      participantchecked3: this.createParticipantForm.controls.participantchecked3.value,
      participantStayDateFrom: this.createParticipantForm.controls.participantStayDateFrom.value,
      participantStayDateTo: this.createParticipantForm.controls.participantStayDateTo.value,
      participantStayMan: this.createParticipantForm.controls.participantStayMan.value,
      participantStayWoman: this.createParticipantForm.controls.participantStayWoman.value
    } as ParticipantData;
    console.log('participant', participant)

    if (this.createParticipantForm.valid) {
      this.data.createNewParticipant(participant).subscribe(
        data => {
          console.log('vytre', data);
          this.resetValidation();
          this.snackBar.openSnackBar('Vous avez soumis la demande avec succès. :)', 'Fermer');
          this.onCheck(event);
        },
        error => { }
      )
      this.sendMessageToPipMethod(participant);
    } else {
      this.snackBar.openSnackWrongBar('Vous n avez pas rempli correctement le formulaire. :(', 'Fermer');
    }
  }

  createForms() {

    this.createParticipantForm = this.formBuilder.group({
      participantTitle: ['', [Validators.required]],
      participantTime: [, [Validators.required, Validators.pattern("^[0-9]*$")]],
      participantFormatId: [, Validators.required],
      participantAnotation: [''],
      participantVideo: [''],
      participantPostSend: [],
      participantAuthor: ['', Validators.required],
      participantAge: [, [Validators.required, Validators.pattern("^[0-9]*$"), Validators.min(0), Validators.max(120)]],
      participantClub: [''],
      participantCountry: [''],
      participantCity: [''],
      participantAddress: [''],
      participantEmail: [, [Validators.required, Validators.email]],
      participantPhone: ['', Validators.pattern("^[0-9]*$")],
      participantchecked1: [''],
      participantchecked2: [''],
      participantchecked3: [''],
      participantStayDateFrom: [],
      participantStayDateTo: [],
      participantStayMan: [],
      participantStayWoman: []
    });
  }

  onCheck(event) {
    if (event.checked == true) {
      this.stay = true;
      this.createParticipantForm.get('participantStayDateFrom').setValidators(Validators.required);
      this.createParticipantForm.get('participantStayDateTo').setValidators(Validators.required);
      this.createParticipantForm.get('participantStayMan').setValidators([Validators.required, Validators.pattern("^[0-9]*$")]);
      this.createParticipantForm.get('participantStayWoman').setValidators([Validators.required, Validators.pattern("^[0-9]*$")]);
      this.createParticipantForm.get('participantStayDateFrom').updateValueAndValidity();
      this.createParticipantForm.get('participantStayDateTo').updateValueAndValidity();
      this.createParticipantForm.get('participantStayMan').updateValueAndValidity();
      this.createParticipantForm.get('participantStayWoman').updateValueAndValidity();

    } else {
      this.stay = false;
      this.createParticipantForm.get('participantStayDateFrom').clearValidators();
      this.createParticipantForm.get('participantStayDateTo').clearValidators();
      this.createParticipantForm.get('participantStayMan').clearValidators();
      this.createParticipantForm.get('participantStayWoman').clearValidators();
      this.createParticipantForm.get('participantStayDateFrom').updateValueAndValidity();
      this.createParticipantForm.get('participantStayDateTo').updateValueAndValidity();
      this.createParticipantForm.get('participantStayMan').updateValueAndValidity();
      this.createParticipantForm.get('participantStayWoman').updateValueAndValidity();

    }
  }

  resetValidation() {
    this.resetForm.resetForm();
    this.createParticipantForm.controls['participantVideo'].enable();
    this.participantchecked3 = false;
    this.stay = false;
  }

  sendMessageToPipMethod(participant: any) {
    this.message = ('Здравей, Група! 🥳\n\nВие имате нов участник. ' + participant.participantAuthor + ', който се е регистрирал с имейл ' + participant.participantEmail + '.\n\nЖелая Ви приятен и усмихнат ден 😊')
    this.data.sendMessageToPip(this.message).subscribe(
      data => {
        console.log('Inside Bot message', data)
      }
    )
  }

  disableLink(event) {
    if (event.checked == true) {
      this.createParticipantForm.controls['participantVideo'].disable();
    } else {
      this.createParticipantForm.controls['participantVideo'].enable();
    }
  }

}
